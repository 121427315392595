<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-09-27 14:33:12
 * @LastEditTime: 2022-02-11 17:56:54
 * @Description: 单体信息管理
 * @Param:
 * @FilePath: \JT_Web_test\src\views\engineeringInfoManagement\monomerInfoTable.vue
 -->

<template>
    <div class="monomerInfoTable">
        <div class="pageContent">
            <div class="fomeStyle_dy">
                <table>
                    <thead>
                        <tr>
                            <th colspan="4">
                                单体信息表
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>单体编号：</th>
                            <td>
                                <el-input v-model="Monomer.monomer_number" disabled placeholder="系统生成"></el-input>
                            </td>
                            <th>登记时间：</th>
                            <td>
                                <el-date-picker
                                    v-model="Monomer.register_time"
                                    type="datetime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="选择登记时间"
                                >
                                </el-date-picker>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <span style="color: red;">*</span>
                                单体名称：
                            </th>
                            <td>
                                <el-input v-model="Monomer.monomer_name" placeholder="请输入单体名称"></el-input>
                            </td>
                            <th>是否图纸结算：</th>
                            <td>
                                <el-radio-group v-model="Monomer.is_drawings_settlement">
                                    <el-radio :label="true">
                                        是
                                    </el-radio>
                                    <el-radio :label="false">
                                        否
                                    </el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <th rowspan="2">
                                楼层数(层)：
                            </th>
                            <td rowspan="2" class="lc">
                                <el-input v-model="Monomer.floors" placeholder="请输入楼层数"></el-input>
                            </td>
                            <th>地上(层)：</th>
                            <td>
                                <el-input v-model="Monomer.ground_floors" placeholder="请输入地上(层)" @change="floorChange('up')"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>地下(层)：</th>
                            <td>
                                <el-input v-model="Monomer.underground_floors" placeholder="请输入地下(层)" @change="floorChange('down')"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>预计方量(m³)：</th>
                            <td>
                                <el-input v-model="Monomer.projected_capacity" placeholder="请输入预计方量"></el-input>
                            </td>
                            <th>当前进度：</th>
                            <td>
                                <el-select v-model="Monomer.current_progress" @change="selectCurrentProgress" placeholder="请选择当前进度">
                                    <el-option
                                        v-for="item in scheduleOptions"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    >
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <th>已用方量(m³)：</th>
                            <td>
                                <el-input v-model="Monomer.used_capacity" disabled placeholder="请输入已用方量"></el-input>
                            </td>
                            <th>预计剩余方量(m³)：</th>
                            <td>
                                <el-input v-model="Monomer.projected_surplus_capacity" disabled placeholder="请输入预计剩余方量"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>备注：</th>
                            <td colspan="3">
                                <el-input
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入备注"
                                    v-model="Monomer.remarks"
                                >
                                </el-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="fomeStyle_dy monomerConcreteDosage" v-if="Monomer.is_drawings_settlement">
                <table>
                    <thead>
                        <tr>
                            <th colspan="6">
                                工程单体砼设计用量
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style="width:0.8rem;">
                                序号
                            </th>
                            <th>楼层</th>
                            <th>施工部位</th>
                            <th>设计标号</th>
                            <th>图纸设计方量(m³)</th>
                            <th>备注</th>
                        </tr>
                        <template v-for="(item, index) in MonomerDrawings">
                            <tr :key="`MonomerDrawings_${index}`">
                                <td>{{ index + 1 }}</td>
                                <td>
                                    <el-select
                                        v-model="item.floor"
                                        @change="selectFloor(item)"
                                        placeholder="请选择楼层"
                                    >
                                        <el-option
                                            v-for="(k,i) in floorOptions"
                                            :key="`floor_${i}`"
                                            :label="k.name"
                                            :value="k.val"
                                        >
                                        </el-option>
                                    </el-select>
                                </td>
                                <td>
                                    <el-select
                                        v-model="item.place"
                                        multiple
                                        collapse-tags
                                        placeholder="请选择施工部位"
                                        @change="selectConstructionSite(item)"
                                    >
                                        <el-option
                                            v-for="k in constructionPosition"
                                            :key="k.code"
                                            :label="k.name"
                                            :value="k.code"
                                        >
                                        </el-option>
                                    </el-select>
                                </td>
                                <td>
                                    <el-input v-model="item.strength_grade_name" placeholder="请输入设计标号"></el-input>
                                </td>
                                <td>
                                    <el-input-number v-model="item.design_quantity" label="请输入图纸设计方量" :controls="false"></el-input-number>
                                </td>
                                <td>
                                    <el-input v-model="item.remarks" placeholder="请输入备注"></el-input>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <button
                    class="addBtn el-icon-plus"
                    @click="addMonomerConcreteDosage"
                >
                    点击此处新增
                </button>
            </div>
        </div>
        <footer>
            <el-button type="primary" @click="saveMonomer" :loading="saveLoading">
                保存
            </el-button>
            <el-button @click="close">
                取消
            </el-button>
        </footer>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    name: 'monomer-info-table',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            saveLoading: false,
            isEdit: false,
            Monomer: {
                register_time: '',
                monomer_number: '',
                monomer_name: '',
                floors: null,
                underground_floors: null,
                ground_floors: null,
                projected_capacity: null,
                current_progress: '',
                current_progress_name: '',
                used_capacity: null,
                projected_surplus_capacity: null,
                remarks: '',
                is_drawings_settlement: null,
            },
            // 当前进度Options
            scheduleOptions: [],
            // 工程单体砼设计用量列表数据
            MonomerDrawings: [],
            // 楼层Options
            floorOptions: [{
                name: '基础层',
                type: 0,
                val: 0,
            }],
            // 施工部位Options
            constructionPosition: [],
        };
    },
    computed: {},
    watch: {},
    created() {
        this.initPage();
    },
    mounted() {},
    destroyed() {},
    methods: {
        async initPage() {
            this.Monomer.register_time = util.currentTimeFormat('yyyy-MM-dd HH:mm:ss');
            this.scheduleOptions = await this.getScheduleOptions();
            this.constructionPosition = await this.getConstructionPosition();
            if ((this.extr.emid || '') !== '') {
                this.isEdit = true;
                this.editInitPage(this.extr.emid);
            } else {
                this.isEdit = false;
            }
        },

        // 编辑初始化页面
        async editInitPage(emid) {
            const row = await this.editInitPageRequest(emid);
            if (row) {
                row.MonomerDrawings.forEach(item => {
                    if ((item.place || '') !== '') {
                        item.place = item.place.split(',');
                        item.place_name = item.place_name.split(',');
                    }
                });
                this.Monomer = row.Monomer;
                this.MonomerDrawings = row.MonomerDrawings;
                this.aboveGroundFloor();
                this.undergroundFloor();
            }
        },

        close() {
            this.$parent.hide();
        },

        // 新增
        addMonomerConcreteDosage() {
            this.MonomerDrawings.push({
                floor: null,
                floor_name: '',
                place: '',
                place_name: '',
                strength_grade: '',
                strength_grade_name: '',
                design_quantity: null,
                remarks: '',
            });
        },

        // 当前进度选择
        selectCurrentProgress() {
            const row = this.scheduleOptions.filter(item => item.code === this.Monomer.current_progress);
            this.Monomer.current_progress_name = row[0].name;
        },

        // 选择楼层
        selectFloor(list) {
            const row = this.floorOptions.filter(item => item.val === list.floor);
            list.floor_name = row[0].name;
        },

        // 施工部位选择
        selectConstructionSite(list) {
            const row = this.constructionPosition.filter(item => list.place.indexOf(item.code) > -1);
            list.place_name = row.map(item => item.name);
        },

        // 楼层编辑，初始化楼层Options
        floorChange(type) {
            if (type === 'up') {
                this.floorOptions = this.floorOptions.filter(item => item.type !== 1);
                this.aboveGroundFloor();
            } else if (type === 'down') {
                this.floorOptions = this.floorOptions.filter(item => item.type !== 2);
                this.undergroundFloor();
            }
        },

        // 地上楼层加载
        aboveGroundFloor() {
            if ((this.Monomer.ground_floors || '') !== '') {
                const new_floors = Number(this.Monomer.ground_floors);
                if ((new_floors || 0) > 0) {
                    for (let i = 1; i <= new_floors; i++) {
                        this.floorOptions.push({
                            name: `第${i}层`,
                            type: 1,
                            val: i,
                        });
                    }
                }
            }
        },
        // 地下楼层加载
        undergroundFloor() {
            if ((this.Monomer.underground_floors || '') !== '') {
                const new_floors = Number(this.Monomer.underground_floors);
                if ((new_floors || 0) > 0) {
                    for (let i = 1; i <= new_floors; i++) {
                        this.floorOptions.push({
                            name: `负${i}层`,
                            type: 2,
                            val: -i,
                        });
                    }
                }
            }
        },

        // 保存单体
        async saveMonomer() {
            this.saveLoading = true;
            if ((this.Monomer.monomer_name || '') === '') {
                this.$message({
                    message: '请先填写单体名称！',
                    type: 'warning',
                });
                this.saveLoading = false;
                return;
            }
            if (!this.isEdit) {
                const newCode = await this.getAutoCode();
                this.Monomer.engineering_id = this.extr.engineering_id;
                this.Monomer.monomer_number = newCode.AutoCodeValue;
                this.Monomer.auto_index = newCode.AutoIndex;
            }
            const Monomer = JSON.parse(JSON.stringify(this.Monomer));
            const MonomerDrawings = JSON.parse(JSON.stringify(this.MonomerDrawings));
            MonomerDrawings.forEach(item => {
                item.place = item.place.toString();
                item.place_name = item.place_name.toString();
            });
            const saveData = {
                Monomer,
                MonomerDrawings,
            };
            const row = await this.saveMonomerRequest(saveData);
            if (row) {
                this.$message({
                    message: '保存成功！',
                    type: 'success',
                });
                this.editInitPage(row);
                this.$parent.hackReset();
            }
            this.saveLoading = false;
        },

        // 获取进度选项数据
        getScheduleOptions() {
            return this.$axios.$get('/interfaceApi/baseinfo/dictionary_config/getlist?parent_code=108&type=1', { defEx: true });
        },

        // 获取施工部位选项数据
        getConstructionPosition() {
            return this.$axios.$get('/interfaceApi/baseinfo/dictionary_config/getlist?parent_code=105-ZT0&type=1', { defEx: true });
        },

        // 获取自动编码
        getAutoCode() {
            // eslint-disable-next-line max-len
            return this.$axios.get(`/interfaceApi/basicplatform/autocode/runtime?code=monomer_code&engineering_id=${this.extr.engineering_id}&engineering_number=${this.extr.engineering_number}`);
        },

        // 保存单体请求
        saveMonomerRequest(data) {
            return this.$axios.$post('/interfaceApi/sale/engineering_manager/save_monomer', data, { defEx: true });
        },

        // 编辑请求页面数据
        editInitPageRequest(emid) {
            return this.$axios.$post(`/interfaceApi/sale/engineering_manager/get_monomer/${emid}`, { defEx: true });
        },
    },
};
</script>
<style lang="stylus" scoped>
.monomerInfoTable
    height 100%
    .pageContent
        height calc(100% - 0.8rem)
        margin-bottom 0.2rem
        overflow-y auto
        padding 0.2rem
        .fomeStyle_dy
            .lc
                vertical-align: top
            .el-date-editor,.el-select
                width 100% !important
            .el-radio-group
                margin-left 0.14rem
        .monomerConcreteDosage
            margin-top 0.2rem
            thead
                th
                    text-align left
            th,td
                text-align center
            td
                >>>.el-input-number
                    width 100%
                    .el-input__inner
                        text-align left
            .addBtn
                width 100%
                height 0.46rem
                background #FCFCFC
                border 2px dashed #eee
                font-size 0.16rem
                color #979EA7
                margin-top 0.05rem
                cursor pointer
    footer
        text-align center
        .el-button
            width 2.2rem
            height 0.4rem
            margin 0 0.3rem
            font-size 0.16rem
</style>